import { render, staticRenderFns } from "./CgField.vue?vue&type=template&id=47ab5e12&scoped=true&"
import script from "./CgField.vue?vue&type=script&lang=js&"
export * from "./CgField.vue?vue&type=script&lang=js&"
import style0 from "./CgField.vue?vue&type=style&index=0&id=47ab5e12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ab5e12",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QSelect,QToggle,QTooltip,QBtn});
