<template>
	<q-card>
		<q-card-section class="text-center">
			<span
				>Are you sure you want to delete <b>{{ label }}</b
				>?</span
			>
		</q-card-section>
		<q-card-actions align="right">
			<q-btn flat label="Cancel" color="primary" v-close-popup />
			<q-btn flat label="Delete" color="primary" @click="confirmDeleteField" />
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	name: 'DeleteDialogCard',
	props: {
		label: {
			type: String,
			required: true,
		},
		id: {
			type: Number,
			required: true,
		},
		cg_id: {
			type: Number,
			required: true,
		},
	},
	methods: {
		...mapActions(['deleteField']),
		async confirmDeleteField() {
			this.$q.loading.show({
				delay: 400
			})
			const response = await this.deleteField({
				cg_id: this.cg_id,
				id: this.id,
			})
			this.$q.loading.hide()
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				this.$emit('close')
				return
			}
			this.$q.notify('The field was deleted successfully.')
			this.$emit('close')
		},
	},
}
</script>

<style>
</style>
