<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Device fields: {{ cg.name }}
		</h1>
		<!-- Disabled Work Email Field -->
		<cg-field
			:disable="true"
			:field_vals="{
				field_type: {
					label: 'Email',
					value: 'EMAIL',
				},
				label: 'Work Email',
			}"
			:errors="{
				label_input_error: false,
				label_input_error_msg: '',
			}"
			:options="[]"
		/>
		<!-- !Disabled Work Email Field -->
		<!-- Cg Fields list -->
		<cg-field
			v-for="(field, index) in contact_fields"
			:disable="field.is_inherited"
			:field_vals="values_arr[index]"
			:errors="errors_arr[index]"
			:key="field.id"
			:options="options"
			@labelChange="(val) => (values_arr[index].label = val)"
			@fieldTypeChange="(val) => (values_arr[index].field_type = val)"
			@piiChange="
				(val) => (values_arr[index].personally_identifiable_information = val)
			"
			@protectionChange="(val) => (values_arr[index].protected_on_import = val)"
			@save="saveField(index)"
			@delete="displayDeleteFieldDialog(field.id, field.label)"
		/>
		<!-- !Cg Fields list -->

		<!-- Delete field dialog -->
		<q-dialog v-model="delete_dialog">
			<delete-dialog-card
				:label="pending_delete_label"
				:id="pending_delete_id"
				:cg_id="parseInt($route.params.id)"
				@close="() => (delete_dialog = false)"
			/>
		</q-dialog>
		<!-- !Delete field dialog -->

		<!-- Add field dialog -->
		<q-dialog v-model="add_dialog">
			<add-field-dialog-card
				:options="options"
				label='device'
				@close="() => (add_dialog = false)"
			/>
		</q-dialog>
		<!-- !Add field dialog -->

		<!-- FAB -->
		<div class="field-fab-wrapper">
			<q-btn
				v-if="$q.screen.gt.sm"
				size="md"
				padding="md lg"
				color="primary"
				icon="add"
				label="Add Device"
				rounded
				@click="() => (add_dialog = true)"
			/>
			<q-btn
				v-else
				size="lg"
				color="primary"
				icon="add"
				round
				@click="() => (add_dialog = true)"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Add Device
				</q-tooltip>
			</q-btn>
		</div>
		<!-- !FAB -->
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CgField from '@/components/datasets/utils/CgField'
import AddFieldDialogCard from '@/components/datasets/utils/AddFieldDialogCard'
import DeleteDialogCard from '@/components/datasets/edit/devices/DeleteDialogCard'

export default {
	name: 'Devices',
	components: { CgField, AddFieldDialogCard, DeleteDialogCard },
	data() {
		return {
			add_dialog: false,
			values_arr: [],
			errors_arr: [],
			options: [],
			pending_delete_id: null,
			delete_dialog: false,
			pending_delete_label: null,
		}
	},
	computed: {
		...mapGetters(['contact_fields', 'customer_group_permissions']),
		cg() {
			return this.$store.getters.customer_groups.filter(
				(cg) => cg.id == this.$route.params.id
			)[0]
		},
	},
	methods: {
		...mapActions([
			'getContactFields',
			'updateField',
			'initAlertDialogOptions',
			'updateDialogOptions',
			'fetchUser',
		]),
		getOptions(){
			let options = [{
				label: 'Email',
				value: 'EMAIL',
			}]
			if (this.customer_group_permissions.voice_channel){
				options.push({
					label: 'Phone(Voice)',
					value: 'PHONE(VOICE)',
				})
			}
			if (this.customer_group_permissions.sms_channel){
				options.push({
					label: 'Phone(SMS)',
					value: 'PHONE(SMS)',
				})
			}
			if (this.customer_group_permissions.voice_channel && this.customer_group_permissions.sms_channel){
				options.push({
					label: 'Phone(SMS & Voice)',
					value: 'PHONE(SMS&VOICE)',
				})
			}
			return options
		},
		validateField(data) {
			if (!data.label) {
				return false
			}
			return true
		},
		showErrorNotification(msg) {
			this.$q.notify({
				timeout: 6700,
				message: msg,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		setValuesArrAndErrorsArr() {
			this.values_arr = this.contact_fields.map((field) => {
				return {
					label: field.label,
					field_type: this.options.filter(
						(o) => o.value === field.field_type
					)[0],
					personally_identifiable_information:
						field.personally_identifiable_information,
					protected_on_import: field.protected_on_import,
				}
			})
			this.errors_arr = this.contact_fields.map((field) => {
				return {
					label_input_error_msg: 'This field is required.',
					label_input_error: false,
				}
			})
		},
		displayDeleteFieldDialog(id, label) {
			this.pending_delete_id = id
			this.pending_delete_label = label
			this.delete_dialog = true
		},
		async saveField(index) {
			this.$q.loading.show({
				delay: 400
			})
			let data = JSON.parse(JSON.stringify(this.contact_fields[index]))
			data = { ...data, ...this.values_arr[index] }
			// Validation
			if (!this.validateField(data)) {
				this.errors_arr[index].label_input_error = true
				this.errors_arr[index].label_input_error_msg = 'This field is required.'
				return
			}
			this.errors_arr[index].label_input_error = false
			data.field_type = data.field_type.value
			let response = await this.updateField({
				data: data,
				cg_id: this.$route.params.id,
			})
			this.$q.loading.hide()
			if (response.error) {
				this.errors_arr[index].label_input_error = true
				this.errors_arr[index].label_input_error_msg = response.error_message
				return
			}
			this.$q.notify('The field was saved successfully.')
			await this.fetchUser()
		},
	},
	created() {
		this.options = this.getOptions()
		this.setValuesArrAndErrorsArr()
		this.options.sort((a, b) => a.label.localeCompare(b.label))
	},
	watch: {
		contact_fields() {
			this.setValuesArrAndErrorsArr()
		},
	},
}
</script>
