<template>
	<div
		:class="{
			'device-wrapper': true,
			'bg-grey-3': disable,
			'bg-grey-1': !disable,
			'q-py-md': true,
			'q-px-sm': true,
			'q-mb-md': true,
			'rounded-borders': true,
			row: true,
			'relative-position': true,
		}"
	>
		<div
			:class="{
				'col-12': true,
				'col-md-5': true,
				'q-px-sm': true,
			}"
		>
			<q-input
				label="Field Label"
				outlined
				bottom-slots
				v-model="label_val"
				:error-message="label_error_message"
				:error="label_error"
				:disable="disable"
			/>
		</div>
		<div
			:class="{
				'col-12': true,
				'col-md-5': true,
				'q-px-sm': true,
			}"
		>
			<q-select
				label="Field type"
				v-model="field_type"
				:disable="disable"
				:options="options"
			/>
		</div>
		<div v-if="!is_work_email" class="col-12 col-md-2 text-right">
			<div>
				<q-toggle
					v-model="personally_identifiable_information"
					label="Personal Data"
					left-label
					:disable="disable"
				>
					<q-tooltip anchor="top middle" :delay="500">
						Personally Identifiable Information
					</q-tooltip>
				</q-toggle>
			</div>
			<div>
				<q-toggle
					v-model="protected_on_import"
					label="Protection"
					left-label
					:disable="disable"
				>
					<q-tooltip anchor="top middle" :delay="500"
						>Protect from deletion via import</q-tooltip
					>
				</q-toggle>
			</div>
		</div>
		<div v-if="!is_work_email" class="col-12 q-mt-md">
			<div class="row justify-end q-pt-sm device-btns-wrapper">
				<q-btn
					outline
					color="primary"
					class="q-mr-md"
					icon-right="save"
					label="Save"
					@click="saveBtnHandler"
					:disable="disable"
				/>
				<q-btn
					outline
					color="primary"
					icon-right="delete"
					label="Delete"
					@click="deleteBtnHandler"
					:disable="disable"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CgField',
	props: {
		disable: Boolean,
		field_vals: {
			type: Object,
			required: true,
		},
		errors: {
			type: Object,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
	},
	computed: {
		is_work_email() {
			return this.field_vals.label === 'Work Email'
		},
		label_val: {
			get() {
				return this.field_vals.label
			},
			set(val) {
				this.$emit('labelChange', val)
			},
		},
		label_error_message() {
			return this.errors.label_input_error_msg
		},
		label_error() {
			return this.errors.label_input_error
		},
		field_type: {
			get() {
				return this.field_vals.field_type
			},
			set(val) {
				this.$emit('fieldTypeChange', val)
			},
		},
		personally_identifiable_information: {
			get() {
				return this.field_vals.personally_identifiable_information
			},
			set(val) {
				this.$emit('piiChange', val)
			},
		},
		protected_on_import: {
			get() {
				return this.field_vals.protected_on_import
			},
			set(val) {
				this.$emit('protectionChange', val)
			},
		},
	},
	methods: {
		saveBtnHandler() {
			this.$emit('save')
		},
		deleteBtnHandler() {
			this.$emit('delete')
		},
	},
}
</script>

<style lang="scss" scoped>
.device-wrapper {
	border: 1px solid $grey-4;
}
.device-btns-wrapper {
	border-top: 1px solid $grey-4;
}
</style>
