var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"text-h5 q-pb-md q-my-md text-left fields-tab-header"},[_vm._v(" Device fields: "+_vm._s(_vm.cg.name)+" ")]),_c('cg-field',{attrs:{"disable":true,"field_vals":{
			field_type: {
				label: 'Email',
				value: 'EMAIL',
			},
			label: 'Work Email',
		},"errors":{
			label_input_error: false,
			label_input_error_msg: '',
		},"options":[]}}),_vm._l((_vm.contact_fields),function(field,index){return _c('cg-field',{key:field.id,attrs:{"disable":field.is_inherited,"field_vals":_vm.values_arr[index],"errors":_vm.errors_arr[index],"options":_vm.options},on:{"labelChange":(val) => (_vm.values_arr[index].label = val),"fieldTypeChange":(val) => (_vm.values_arr[index].field_type = val),"piiChange":(val) => (_vm.values_arr[index].personally_identifiable_information = val),"protectionChange":(val) => (_vm.values_arr[index].protected_on_import = val),"save":function($event){return _vm.saveField(index)},"delete":function($event){return _vm.displayDeleteFieldDialog(field.id, field.label)}}})}),_c('q-dialog',{model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('delete-dialog-card',{attrs:{"label":_vm.pending_delete_label,"id":_vm.pending_delete_id,"cg_id":parseInt(_vm.$route.params.id)},on:{"close":() => (_vm.delete_dialog = false)}})],1),_c('q-dialog',{model:{value:(_vm.add_dialog),callback:function ($$v) {_vm.add_dialog=$$v},expression:"add_dialog"}},[_c('add-field-dialog-card',{attrs:{"options":_vm.options,"label":"device"},on:{"close":() => (_vm.add_dialog = false)}})],1),_c('div',{staticClass:"field-fab-wrapper"},[(_vm.$q.screen.gt.sm)?_c('q-btn',{attrs:{"size":"md","padding":"md lg","color":"primary","icon":"add","label":"Add Device","rounded":""},on:{"click":() => (_vm.add_dialog = true)}}):_c('q-btn',{attrs:{"size":"lg","color":"primary","icon":"add","round":""},on:{"click":() => (_vm.add_dialog = true)}},[_c('q-tooltip',{attrs:{"anchor":"top middle","delay":500}},[_vm._v(" Add Device ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }