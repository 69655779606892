<template>
	<q-card class="q-pa-md" style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center">
			<span class="text-h5">Add new {{ label }}</span>
		</q-card-section>
		<q-card-section>
			<q-input
				label="Field name"
				bottom-slots
				v-model="add_data.label"
				:error="label_error"
				:error-message="label_error_msg"
			/>
			<q-select
				label="Field type"
				bottom-slots
				v-model="add_data.field_type"
				:error="field_type_error"
				:error-message="field_type_error_msg"
				:options="options"
			/>
			<div class="flex justify-end">
				<q-toggle
					v-model="add_data.personally_identifiable_information"
					label="Personal Data"
					left-label
				/>
				<q-toggle
					v-model="add_data.protected_on_import"
					label="Protection"
					left-label
				/>
			</div>
		</q-card-section>
		<q-card-actions align='right'>
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')" />
			<q-btn flat label="Add" color="primary" @click="addBtnHandler" />
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'AddFieldDialogCard',
	props: {
		options: {
			type: Array,
			required: true,
		},
		label: String
	},
	data() {
		return {
			add_data: {
				label: null,
				field_type: null,
				personally_identifiable_information: false,
				protected_on_import: true,
			},
			label_error: false,
			label_error_msg: null,
			field_type_error: false,
			field_type_error_msg: null,
		}
	},
	methods: {
		...mapActions(['createField', 'fetchUser']),
		validateField(data) {
			if (!data.label) {
				return false
			}
			return true
		},
		async addBtnHandler() {
			let field_data = {
				...this.add_data,
				customer_group_id: parseInt(this.$route.params.id),
			}
			// Validation
			let valid = this.validateField(field_data) && this.add_data.field_type
			if (!this.validateField(field_data)) {
				this.label_error = true
				this.label_error_msg = 'This field is required.'
			} else {
				this.label_error = false
			}
			if (!this.add_data.field_type) {
				this.field_type_error = true
				this.field_type_error_msg = 'This field is required.'
			} else {
				this.field_type_error = false
			}
			if (!valid) {
				return
			}
			field_data.field_type = field_data.field_type.value
			this.$q.loading.show({
				delay: 400,
			})
			let response = await this.createField(field_data)
			this.$q.loading.hide()
			if (response.error) {
				this.label_error = true
				this.label_error_msg = response.error_message
				return
			}
			this.$q.notify('The field was created successfully.')
			this.add_data = {
				label: null,
				field_type: null,
				personally_identifiable_information: false,
				protected_on_import: true,
			}
			await this.fetchUser()
			this.$emit('close')
		},
	},
}
</script>

<style>
</style>
