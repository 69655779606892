var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		'device-wrapper': true,
		'bg-grey-3': _vm.disable,
		'bg-grey-1': !_vm.disable,
		'q-py-md': true,
		'q-px-sm': true,
		'q-mb-md': true,
		'rounded-borders': true,
		row: true,
		'relative-position': true,
	}},[_c('div',{class:{
			'col-12': true,
			'col-md-5': true,
			'q-px-sm': true,
		}},[_c('q-input',{attrs:{"label":"Field Label","outlined":"","bottom-slots":"","error-message":_vm.label_error_message,"error":_vm.label_error,"disable":_vm.disable},model:{value:(_vm.label_val),callback:function ($$v) {_vm.label_val=$$v},expression:"label_val"}})],1),_c('div',{class:{
			'col-12': true,
			'col-md-5': true,
			'q-px-sm': true,
		}},[_c('q-select',{attrs:{"label":"Field type","disable":_vm.disable,"options":_vm.options},model:{value:(_vm.field_type),callback:function ($$v) {_vm.field_type=$$v},expression:"field_type"}})],1),(!_vm.is_work_email)?_c('div',{staticClass:"col-12 col-md-2 text-right"},[_c('div',[_c('q-toggle',{attrs:{"label":"Personal Data","left-label":"","disable":_vm.disable},model:{value:(_vm.personally_identifiable_information),callback:function ($$v) {_vm.personally_identifiable_information=$$v},expression:"personally_identifiable_information"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","delay":500}},[_vm._v(" Personally Identifiable Information ")])],1)],1),_c('div',[_c('q-toggle',{attrs:{"label":"Protection","left-label":"","disable":_vm.disable},model:{value:(_vm.protected_on_import),callback:function ($$v) {_vm.protected_on_import=$$v},expression:"protected_on_import"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","delay":500}},[_vm._v("Protect from deletion via import")])],1)],1)]):_vm._e(),(!_vm.is_work_email)?_c('div',{staticClass:"col-12 q-mt-md"},[_c('div',{staticClass:"row justify-end q-pt-sm device-btns-wrapper"},[_c('q-btn',{staticClass:"q-mr-md",attrs:{"outline":"","color":"primary","icon-right":"save","label":"Save","disable":_vm.disable},on:{"click":_vm.saveBtnHandler}}),_c('q-btn',{attrs:{"outline":"","color":"primary","icon-right":"delete","label":"Delete","disable":_vm.disable},on:{"click":_vm.deleteBtnHandler}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }